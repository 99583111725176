// uploadReducer.js
import {
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  IS_VERIFYING,
  PARAMS,
  UNSUPPORTED_MEDIA,
  FILE_ALREADY_EXISTS,
  DOCUMENT_NOT_ORIGINAL,
  BUTTONID_IS_NULL,
  REDIRECT_TO_UPLOAD,
  SOMETHING_WENT_WRONG,
  PASSWORD_PROTECTED,
} from "../actions/actionTypes";

const initialState = {
  uploadedFile: null,
  isVerifying: false,
  error: null,
  params: [],
  documentIsVerified: false,
  documentIsWrong: false,
  unsupportedMedia: false,
  fileAlreadyExists: false,
  documentIsNotOriginal: false,
  buttonIdIsNull: false,
  somethingWentWrong: false,
  passwordProtected: false,
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case UPLOAD_FILE_SUCCESS:
      console.log("here is inside the upload file success reducer");
      return {
        ...state,
        uploadedFile: action.payload,
        isVerifying: false,
        documentIsVerified: true,
        documentIsNotOriginal: false,
        documentIsWrong: false,
        error: null,
      };
    case UPLOAD_FILE_FAILURE:
      console.log("here is inside the upload file failure reducer");
      return {
        ...state,
        isVerifying: false,
        documentIsVerified: false,
        documentIsWrong: true,
        error: action.payload,
      };
    case IS_VERIFYING:
      console.log("here is inside the upload file varifying reducer");
      return {
        ...state,
        isVerifying: action.payload,
      };
    case UNSUPPORTED_MEDIA:
      console.log("Inside Unsupported Media reducer", action);
      return {
        ...state,
        unsupportedMedia: action.payload,
        documentIsVerified: false,
        documentIsNotOriginal: false,
        isVerifying: false,
      };
    case FILE_ALREADY_EXISTS:
      console.log("Inside File Already exists reducer", action);
      return {
        ...state,
        fileAlreadyExists: action.payload,
      };

    case DOCUMENT_NOT_ORIGINAL:
      console.log("Document is not original");
      return {
        ...state,
        // isVerifying:false,
        documentIsVerified: false,
        documentIsNotOriginal: action.payload,
      };

    case BUTTONID_IS_NULL:
      console.log("Button id is null");
      return {
        ...state,
        buttonIdIsNull: true,
      };

    case REDIRECT_TO_UPLOAD:
      console.log("redirect to upload");
      return {
        ...state,
        documentIsNotOriginal: false,
        documentIsVerified: false,
      };

    case SOMETHING_WENT_WRONG:
      console.log("something went wrong reducer");
      return {
        ...initialState,
        somethingWentWrong: action.payload,
      };

    case PASSWORD_PROTECTED:
      console.log("password protected reducer");
      return {
        ...state,
        passwordProtected: action.payload,
        documentIsVerified: false,
        documentIsNotOriginal: false,
        isVerifying: false,
      };

    default:
      return state;
  }
};

export default uploadReducer;
