import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./statementUploader.css";
import { Modal, ModalBody, Button } from "reactstrap";
import "./statementUploader.css";
import LiveFeedbackPopup from "../view/LiveFeedbackPopup";
import { useSelector } from "react-redux";
import ShoppingCart from "./cart";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import shoppingCartImg from "../assets/shopping-cart-svgrepo-com.svg";

const DiroLogo = ({ color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={"100px"}
    height={"100px"}
    viewBox="0 0 117.72 117.72"
    style={{ enableBackground: "new 0 0 117.72 117.72" }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
        .st0{fill:#01A601;}
      `}
    </style>
    <g>
      <path
        className="st0"
        d="M58.86,0c9.13,0,17.77,2.08,25.49,5.79c-3.16,2.5-6.09,4.9-8.82,7.21c-5.2-1.89-10.81-2.92-16.66-2.92 
        c-13.47,0-25.67,5.46-34.49,14.29c-8.83,8.83-14.29,21.02-14.29,34.49c0,13.47,5.46,25.66,14.29,34.49 
        c8.83,8.83,21.02,14.29,34.49,14.29s25.67-5.46,34.49-14.29c8.83-8.83,14.29-21.02,14.29-34.49c0-3.2-0.31-6.34-0.9-9.37 
        c2.53-3.3,5.12-6.59,7.77-9.85c2.08,6.02,3.21,12.49,3.21,19.22c0,16.25-6.59,30.97-17.24,41.62 
        c-10.65,10.65-25.37,17.24-41.62,17.24c-16.25,0-30.97-6.59-41.62-17.24C6.59,89.83,0,75.11,0,58.86 
        c0-16.25,6.59-30.97,17.24-41.62S42.61,0,58.86,0L58.86,0z M31.44,49.19L45.8,49l1.07,0.28c2.9,1.67,5.63,3.58,8.18,5.74 
        c1.84,1.56,3.6,3.26,5.27,5.1c5.15-8.29,10.64-15.9,16.44-22.9c6.35-7.67,13.09-14.63,20.17-20.98l1.4-0.54H114l-3.16,3.51 
        C101.13,30,92.32,41.15,84.36,52.65C76.4,64.16,69.28,76.04,62.95,88.27l-1.97,3.8l-1.81-3.87c-3.34-7.17-7.34-13.75-12.11-19.63 
        c-4.77-5.88-10.32-11.1-16.79-15.54L31.44,49.19L31.44,49.19z"
      />
    </g>
  </svg>
);

var SuccessScreen = ({
  liveFeedbackResponses,
  filesForMultiUpload,
  handleFileSelect,
  removeFileFromMultiUploadFiles,
  removeLivefeedbackResponse,
  loaderValue,
  setLoaderValue,
  oneShotUploadAction,
  modalColor,
  PocError,
}) => {
  const button = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.button
  );

  const category = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.category
  );

  const [showThankyou, setShowThankyou] = useState(false);
  const [toRedirect, setToRedirect] = useState(false);
  const handleBrowseClick = () => {
    // Trigger click event on file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const fileInputRef = useRef(null);

  const handleSubmit = () => {
    setShowThankyou(true);
    oneShotUploadAction(setToRedirect);
  };

  useEffect(() => {
    //reirect.url
    console.log("redirect", toRedirect, button.redirecturl);
    if (button.redirecturl && toRedirect) {
      window.location.href = button.redirecturl;
    }
  }, [setToRedirect]);

  return (
    <Modal
      isOpen={true}
      className={"modal-centered"}
      backdropClassName="custom-backdrop"
      keyboard={false}
      backdrop={true}
      size="md"
      scrollable={true}
      style={{ marginInline: "auto" }}
    >
      <ModalBody className=" model-body">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "center",
          }}
        >
          {/* {button?.multidownload && !showThankyou ? (
            <div style={{ width: 130, height: 130, margin: "20px auto" }}>
              <CircularProgressbarWithChildren
                value={loaderValue}
                styles={buildStyles({
                  pathColor: "green",
                  // trailColor: "gold",
                })}
              >
                <img height={100} src={shoppingCartImg} alt="cart" />
              </CircularProgressbarWithChildren>
            </div>
          ) : (
            <div className="mt-3 ">
              <DiroLogo />
            </div>
          )} */}
          {console.log("loader value", loaderValue)}
          {!showThankyou && (
            <div style={{ width: 130, height: 130, margin: "20px auto" }}>
              <CircularProgressbarWithChildren
                value={loaderValue}
                styles={buildStyles({
                  pathColor: "green",
                  // trailColor: "gold",
                })}
              >
                <img height={100} src={shoppingCartImg} alt="cart" />
              </CircularProgressbarWithChildren>
            </div>
          )}
          {showThankyou && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="mt-3" style={{ display: "flex", margin: "auto" }}>
                <DiroLogo />
              </div>
              <div>
                {" "}
                <h2
                  style={{
                    fontFamily: "",
                    marginTop: "2rem",
                    width: "20rem",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  Thank you
                </h2>
              </div>
            </div>
          )}
          {!showThankyou && (category === "bank" || category === "address") && (
            <div
              style={{ width: "100%", margin: "20px 0px", paddingLeft: "20px" }}
            >
              <LiveFeedbackPopup
                liveFeedback={liveFeedbackResponses}
                removeFileFromMultiUploadFiles={removeFileFromMultiUploadFiles}
                removeLivefeedbackResponse={removeLivefeedbackResponse}
                setLoaderValue={setLoaderValue}
              />
            </div>
          )}
          <div style={{ display: "flex", gap: "20px" }}>
            {!showThankyou && (
              <>
                {" "}
                <Button
                  className="btn cbtn"
                  style={{
                    borderRadius: "35px",
                    fontFamily: "Montserrat",
                    // border: "solid 2px #00bcd4",
                    backgroundColor: "black",
                    color: "#fff",
                    padding: "10px 20px",
                    fontSize: "14px",
                    fontWeight: "600",
                    // width: "100%",
                    margin: "1rem 0rem 0rem 0rem",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </>
            )}

            {button?.multidownload &&
              filesForMultiUpload.length > 0 &&
              !showThankyou && (
                <div>
                  {" "}
                  <Button
                    className="btn cbtn"
                    style={{
                      borderRadius: "35px",
                      fontFamily: "Montserrat",
                      // border: "solid 2px #00bcd4",
                      backgroundColor: "black",
                      color: "#fff",
                      padding: "10px 20px",
                      fontSize: "14px",
                      fontWeight: "600",
                      // width: "100%",
                      margin: "1rem 0rem 0rem 0rem",
                    }}
                    onClick={handleBrowseClick}
                  >
                    upload more
                  </Button>
                  <input
                    ref={fileInputRef}
                    className="d-none"
                    type="file"
                    onChange={handleFileSelect}
                    // multiple={button?.multidownload}
                  />
                </div>
              )}
          </div>
        </div>
        <div>
          {/* <p className="uploader-powered mt-2 mb-1">Powered by DIRO</p> */}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessScreen;
