import axios from 'axios';
import {env as environment} from '../environments'


export const sendSentryLogs = async (sessionObjData) => {

    const headers ={
        "Content-Type": "application/json"
    }

    const response = await axios.post(environment.sentryLogs,sessionObjData,headers)
    if(response.status === 200){
        console.log("---log Post Sucessfully----");
        return;
    }
    else{
        console.log("----LogPostFailed-----");
    }
}
