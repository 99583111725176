import axios from "axios";
import { env as environment } from "../environments";
import {
  UPLOAD_S3_FILE_START,
  UPLOAD_S3_FILE_SUCCESS,
  UPLOAD_S3_FILE_FAILURE,
  LIVEFEEDBACK_START,
  LIVEFEEDBACK_SUCCESS,
  LIVEFEEDBACK_FAILURE,
} from "../actions/actionTypes";
import { updateSession } from "./updateSessionHandler";
import { sendSentryLogs } from "./sentry_logs";

const AWS = require("aws-sdk");
let awsCreds = {};


if (environment?.env === "prodeu") {
  console.log("environment env check if",environment?.env)
  awsCreds = {
    region: process.env.REACT_APP_S3_REGION_PROD,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID_PROD,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_PROD,
  };
} else {
  console.log("environment env check else",environment?.env)
  awsCreds = {
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };
}

AWS.config.update(awsCreds);
const Aws_S3 = new AWS.S3();

export const s3fileHandler = (file, sessionId, category) => {
  console.log("here is the category " + category);
  const uploadParams = {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key: "",
    Body: file,
    ContentType: "application/pdf",
  };

  let datetime = new Date().getTime().toString();
  uploadParams.Key = sessionId + "-" + datetime + ".pdf";

  return async (dispatch) => {
    try {
      dispatch({ type: UPLOAD_S3_FILE_START });

      const s3Promise = new Promise((resolve, reject) => {
        Aws_S3.upload(uploadParams, function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
            dispatch({ type: UPLOAD_S3_FILE_SUCCESS, payload: data });
          }
        });
      });

      const liveFeedbackPromise = (async () => {
        if (category === "bank" || category === "address") {
          dispatch({ type: LIVEFEEDBACK_START });

          const Header = {
            "Content-Type": "application/json",
          };
          const liveFeedbackForm = new FormData();
          liveFeedbackForm.append("file", file, uploadParams.Key);
          liveFeedbackForm.append("category", category);
          liveFeedbackForm.append("sessionid", sessionId);

          const response = await axios.post(
            environment.getProcessedDataFromChatGpt,
            liveFeedbackForm,
            Header
          );

          const chatGptResponse = response.data;
          dispatch({ type: LIVEFEEDBACK_SUCCESS, payload: chatGptResponse });

          var send_livefeedback = {
            sessionid: sessionId,
            data: { livefeedback: chatGptResponse },
          };
          await dispatch(updateSession(send_livefeedback, sessionId));
        }
      })();

      // Wait for all promises to resolve
      await Promise.all([s3Promise, liveFeedbackPromise]);

      // Proceed to the next block after all promises have resolved
      console.log("All promises resolved successfully");
    } catch (error) {
      var SentryLogMessage = {
        version: "1.0",
        host: "pdf verifier upload Process",
        short_message: "Failed S3 file upload",
        full_message: `S3 Bucket File upload failed with error: ${error.message}`,
        timestamp: Date.now() / 1000,
        level: 3,
        facility: "uplaods3_Action.js",
        _sessionid: sessionId,
        app_name: "pdf upload Verifier ",
        _ENV: "stage2",
      };
      await sendSentryLogs(SentryLogMessage);
      console.error("Error in s3fileHandler:", error);
      dispatch({ type: UPLOAD_S3_FILE_FAILURE, payload: error });
    }
  };
};
