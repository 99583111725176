import axios from "axios";
import {env as environment} from '../environments';
import {SESSION_UPDATE,SESSION_FAILURE} from '../actions/actionTypes';

export const updateSession=( data, session)=>{    
    const Header= {
        headers: {
            "Content-Type": "application/json"
        }
    }

    return (dispatch) =>{
    axios.post(environment.update_session,data, Header)
    .then((response)=>{
        dispatch({type:SESSION_UPDATE})
        console.log(response, 'response of the update session')
    })
    .catch((error)=>{
        dispatch({type:SESSION_FAILURE, paylaod:error})
    })
    }
}
