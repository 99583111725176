import React, { useState } from "react";
import {useSelector} from 'react-redux'
import "./index.css";
import ReportIssue from "../../templates/reportIssue.template";
import { sendLogs } from "../../../helpers/sendLogs";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useSelector((state) => state.upload?.params);

  
  const VerificationLinkPoc = useSelector((state)=> state?.verificationLinkPOC?.verificationPocData?.verificationlink)
  const lastLinkedClickedData = useSelector(state => state?.lastLinkClicked?.dataSuccess);
  localStorage.setItem('ThemeColor', lastLinkedClickedData?.data.setcolor)
  const sessionId = lastLinkedClickedData?.inviteid
  const currentYear = new Date().getFullYear();
  const handleReportIssue = async() => {
    console.log("Toggling the report issue modal");
    setIsModalOpen(prevState => !prevState);
    await sendLogs("Report issue", "report issue is opened",'footer/index.js',sessionId,1)
  };

  return (
    <>
   {isModalOpen && (
        <ReportIssue modalColor={null} setModalOpen={setIsModalOpen} />
      )}
      <div className="footer">
      {}
      {VerificationLinkPoc && (
        <div class="fixed-bottom">
          <div class="" style={{ border: "none"}} id="myNavbar">
              <button
                class="d-flex vibrate-animation"
                id="take-scree-btn"
                style={{
                  background: "black",
                  color: "white",
                  margin: "0.5rem",
                  padding: "0px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                }}
                onClick={handleReportIssue}
              >
                <h5 class="card-title d-flex">
                  <svg
                    width="16"
                    height="26"
                    viewBox="0 0 16 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      border: "2px solid white",
                      width: "2.2rem",
                      height: "2.7rem",
                    }}
                  >
                    <path
                      d="M0.546241 6.58399C0.542462 6.67315 0.556961 6.76214 0.588847 6.84549C0.620733 6.92885 0.669331 7.00479 0.731654 7.06867C0.793977 7.13254 0.868708 7.18299 0.95125 7.21692C1.03379 7.25084 1.1224 7.26753 1.21163 7.26594H3.48941C3.87042 7.26594 4.17413 6.95395 4.22383 6.5757C4.47231 4.76452 5.71474 3.44479 7.92902 3.44479C9.82303 3.44479 11.5569 4.39179 11.5569 6.66958C11.5569 8.42278 10.5243 9.22898 8.89259 10.4548C7.03447 11.8049 5.56289 13.3814 5.6678 15.9408L5.67609 16.54C5.67898 16.7211 5.75298 16.8938 5.88211 17.0209C6.01125 17.148 6.18516 17.2192 6.36632 17.2192H8.60545C8.78852 17.2192 8.96408 17.1464 9.09352 17.017C9.22297 16.8875 9.29569 16.712 9.29569 16.5289V16.239C9.29569 14.2567 10.0494 13.6796 12.0843 12.1363C13.7657 10.8579 15.5189 9.43881 15.5189 6.45975C15.5189 2.28795 11.9959 0.272461 8.13885 0.272461C4.64073 0.272461 0.808532 1.90142 0.546241 6.58399ZM4.84504 22.4953C4.84504 23.9669 6.01844 25.0547 7.6336 25.0547C9.31502 25.0547 10.4719 23.9669 10.4719 22.4953C10.4719 20.9713 9.31226 19.9 7.63084 19.9C6.01844 19.9 4.84504 20.9713 4.84504 22.4953Z"
                      fill="white"
                    />
                  </svg>

                  <span
                    class="text-wrap text-start pe-2"
                    style={{
                      padding: "12px",
                      maxWidth: "auto",
                      alignItems: "center",
                      fontSize: "16px",
                    }}
                  >
                    Report issue
                  </span>
                </h5>
              </button>
         </div>
        </div>
      )}
      {params.buttonid?<div className="footer-text">
         <p> Internet Original Documents, Inc. New York, USA. All Rights Reserved @
          {currentYear}, — EULA — Privacy Policy</p>
        </div> :<p style={{fontSize:"14px",fontFamily:"sans-serif"}}> Internet Original Documents, Inc. New York, USA. All Rights Reserved @
          {currentYear}, — EULA — Privacy Policy</p>}
      </div>
    </>
  );
};

export default Footer;
