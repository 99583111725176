import React, { useEffect, useState } from "react";
import HeaderComponent from "../Components/view/Header";
import UploadFile from "../Components/view/UploadFile";
import Footer from "../Components/view/Footer";
import { PARAMS } from "../actions/actionTypes";
import { redirect } from "react-router-dom";
import "./index.css";
import VerifiedResponse from "../Components/view/VerifiedResponse/index";
import { Card, Col, Row, Container } from "reactstrap";
import { VerificationLinkPoc } from '../actions/VerificationLinkPoc';
import { useSelector, useDispatch } from "react-redux";
import Privacy from "../Components/templates/privacy.template";


const HomePage = () => {
  const dispatch = useDispatch();
  const verifiedData = useSelector((state) => state?.upload?.uploadedFile);
  const isVerifying = useSelector((state) => state?.upload?.isVerifying);
  const lastLinkClicked = useSelector((state) => state.lastlinkClicked?.dataSuccess);
  const VerificationLinkPOC = useSelector(
    (state) => state?.verificationLinkPOC?.verificationPocData?.verificationlink
  );
  const nses = VerificationLinkPOC?.lastIndexOf("/");
  const sessionId = VerificationLinkPOC?.substring(nses + 1);
  const [params, setParams] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const newParams = {};
    for (const [key, value] of queryParams) {
      newParams[key] = value;
    }
    dispatch(setStateParams(newParams));
    if (Object.keys(newParams).length > 0) {
      setParams(newParams);
    }
  }, []);

  useEffect(() => {
    if (params) {
      dispatch(VerificationLinkPoc(params));
    }
  }, [params]);

  const setStateParams = (newParams) => ({
    type: PARAMS,
    payload: newParams,
  });

  console.log("store data",verifiedData,lastLinkClicked,isVerifying )

  return (
    <>
      <div className="homepage">
        <HeaderComponent />
        <div className="homepage-content">
          <Container
            style={{ fontFamily: "'Prata', serif", height: "auto" }}
            className="homepage-content-container"
          >
            <div
              className="verify-heading"
              style={{
                fontSize: "2.5rem",
                fontWeight: "500",
                // color: "var(--bs-heading-color)",
                color: "#0f3f61",
                marginBottom: "10px",
              }}
            >
              Verify authenticity of document
            </div>
            <Row className="justify-content-center content-card-wrap">
              <Col>
                <Card
                  className="cus-card"
                  style={{
                    position: "relative",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    minHeight: "440px",
                  }}
                >
                  {verifiedData && <VerifiedResponse />}
                  <UploadFile
                    verifiedData={verifiedData}
                    isVerifying={isVerifying}
                  />
                </Card>
              </Col>
            </Row>
            <Footer />
          </Container>
        </div>
      </div>
    </>
  );
};

export default HomePage;
