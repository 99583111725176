import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./statementUploader.css";
import { Modal, ModalBody, Button } from "reactstrap";
import "./statementUploader.css";
import {
  ALL_LIVEFEEDBACK_DATA,
  LIVEFEEDBACK_INVALID_DOC,
  PASSWORD_PROTECTED,
  REDIRECT_TO_UPLOAD,
  SOMETHING_WENT_WRONG,
} from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import diroLogo from "../assets/dirologo.svg";

const TechnicalErrorIconSvg = ({ color }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#E75151" />
    <path
      d="M13.5089 7.5H16.5177L16.0118 18.0685H13.9882L13.5089 7.5ZM15.0266 23.0769C14.5296 23.0769 14.1124 22.9445 13.7751 22.6798C13.4379 22.4003 13.2692 22.062 13.2692 21.6649C13.2692 21.2677 13.4379 20.9367 13.7751 20.672C14.1124 20.3925 14.5296 20.2528 15.0266 20.2528C15.5237 20.2528 15.9319 20.3925 16.2515 20.672C16.571 20.9367 16.7308 21.2677 16.7308 21.6649C16.7308 22.062 16.5621 22.4003 16.2248 22.6798C15.9053 22.9445 15.5059 23.0769 15.0266 23.0769Z"
      fill="#FCFBFB"
    />
  </svg>
);

var PasswordProtected = ({
  allFiles,
  setAllFiles,
  setTryAgain,
  modalColor,
  PocError,
}) => {
  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const removeFileFromAllFiles = (index) => {
    const temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles([...temp]);
  };

  const removeLivefeedbackResponseFromAllResponses = (index) => {
    const temp = [...allLiveFeedbackResponses];
    temp.splice(index, 1);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [...temp] });
  };

  const handleDelete = () => {
    removeFileFromAllFiles(allFiles.length - 1);
    removeLivefeedbackResponseFromAllResponses(
      allLiveFeedbackResponses.length - 1
    );
  };

  const redirectToUpload = () => {
    console.log("allfiles length redirect to upload", allFiles);
    if (allFiles.length < 2) {
      dispatch({ type: REDIRECT_TO_UPLOAD });
    }
  };

  return (
    <Modal
      isOpen={true}
      className={"modal-centered"}
      backdropClassName="custom-backdrop"
      keyboard={false}
      backdrop={true}
      size="md"
      scrollable={true}
      style={{ width: "450px", margin: "auto" }}
      contentClassName="modal-content-style"
    >
      <ModalBody className=" model-body">
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "center",
            // padding:"20px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div>
              <TechnicalErrorIconSvg />
            </div>
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Upload Failed!
            </div>
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Password-protected files are not supported.
            </div>
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {" "}
              Please upload an unprotected PDF
            </div>

            <Button
              className="btn cbtn"
              id="browse"
              style={{
                // borderRadius: "35px",
                fontFamily: "Montserrat",
                // border: "solid 2px #00bcd4",
                backgroundColor: "black",
                color: "#fff",
                padding: "8px 30px",
                fontSize: "16px",
                fontWeight: "600",
                // width: "100%",
                // margin: "1rem 0rem 2rem 0rem",
              }}
              onClick={() => {
                dispatch({ type: PASSWORD_PROTECTED, payload: false });
                handleDelete();
                redirectToUpload();
              }}
            >
              Try Again
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              fontSize: "13px",
              fontWeight: 600,
            }}
          >
            Powered by
          </div>
          <img src={diroLogo} height={12} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PasswordProtected;
