import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import deleteIcon from "../../assets/rubbish-bin_64022.png";
import { ALL_LIVEFEEDBACK_DATA } from "../../../actions/actionTypes";
import check from "../../../images/check.png";
import wrong from "../../../images/remove.png";

const LiveFeedback = ({ setLoaderValue, allFiles, setAllFiles }) => {
  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const lastFileLiveFeedbackData = useSelector(
    (state) => state?.livefeedback?.feedbackSuccess?.response
  );

  console.log("all files", allFiles);

  console.log(
    "allLiveFeedbackResponses",
    allLiveFeedbackResponses,
    lastFileLiveFeedbackData
  );

  const button = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.button
  );
  const crtime = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.crtime
  );
  const category = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.category
  );

  console.log("livefeedbackpopup data", allLiveFeedbackResponses);

  const [monthsLiesInUploadBtn, setMonthsLiesInUploadBtn] = useState([]);
  const [monthsLiesInUploadedDocs, setMonthsLiesInUploadedDocs] = useState([]);

  console.log(monthsLiesInUploadBtn, monthsLiesInUploadedDocs);

  const removeFileFromAllFiles = (index) => {
    const temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles([...temp]);
  };

  const removeLivefeedbackResponseFromAllResponses = (index) => {
    const temp = [...allLiveFeedbackResponses];
    temp.splice(index, 1);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [...temp] });
  };

  const handleDelete = (index) => {
    removeFileFromAllFiles(index);
    removeLivefeedbackResponseFromAllResponses(index);
  };

  const createdMonth = new Date(parseInt(crtime)).getMonth() + 1;

  const createdYear = new Date(parseInt(crtime)).getFullYear();

  const getMonthAndYear = () => {
    let month;
    let year = createdYear;
    month = createdMonth - parseInt(button?.dataRangeInMonths);
    if (month === 0) {
      month = 12;
      year = createdYear - 1;
    }
    if (month < 0) {
      month = 12 - Math.abs(month);
      year = createdYear - 1;
    }
    return [month, year];
  };

  function dateRange(startDate, endDate) {
    console.log("date range func", startDate, endDate);
    var start = startDate?.split("-");
    var end = endDate?.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    console.log("datess", dates);
    return dates;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (
      lastFileLiveFeedbackData &&
      allFiles?.length === allLiveFeedbackResponses.length + 1
    )
      dispatch({
        type: ALL_LIVEFEEDBACK_DATA,
        payload: [...allLiveFeedbackResponses, lastFileLiveFeedbackData],
      });
  }, [lastFileLiveFeedbackData]);

  useEffect(() => {
    if (crtime) {
      console.log("set months useeffecet live responses", crtime);
      setMonthsLiesInUploadBtn(
        dateRange(
          `${getMonthAndYear()[1]}-${getMonthAndYear()[0]}-01`,
          `${createdYear}-${createdMonth}-01`
        )
      );
    }
  }, [crtime]);

  useEffect(() => {
    console.log("livefeedback useeffecet live responses");
    let temp = [];
    allLiveFeedbackResponses?.map((item, index) => {
      if (!item.document_type.includes("Invalid") && item?.period) {
        temp = [
          ...temp,
          ...dateRange(
            item?.period?.split("-")[0].replaceAll("/", "-"),
            item?.period?.split("-")[1].replaceAll("/", "-")
          ),
        ];
      }
    });
    setMonthsLiesInUploadedDocs([...new Set([...temp])]);
  }, [allLiveFeedbackResponses]);

  useEffect(() => {
    console.log("set loader useeffecet live responses");

    let commonMonthsCount = 0;
    monthsLiesInUploadBtn.map((item) => {
      console.log(
        "useeffect months map inside set loader useEffect",
        monthsLiesInUploadBtn,
        monthsLiesInUploadedDocs,
        item
      );
      if (monthsLiesInUploadedDocs?.includes(item)) {
        console.log(console.log("useeffcet", "if"));
        commonMonthsCount += 1;
      }
    });
    console.log("common months", commonMonthsCount);

    console.log(
      "loader value check livefeedback months lies in uploaded docs",
      monthsLiesInUploadedDocs
    );

    // set loader value 0, when there is no uploaded file, occurs when "try another" button hit.
    if (allFiles?.length === 0) {
      setLoaderValue(0);
    }
    // set dynamic loader value , when there is atleast one or more uploaded file(s), occurs when "try another" button hit.
    else {
      // if category from button is bank and multidownload key is true
      if (category === "bank" && button?.multidownload) {
        setLoaderValue(
          (commonMonthsCount / monthsLiesInUploadBtn.length) * 100
        );
      }
      // if category from button is bank and multidownload key is true
      else if (button?.multidownload) {
        const dataRangeInMonths = parseInt(button?.dataRangeInMonths);

        // if dataRangeInMonths is more than 0.
        if (dataRangeInMonths) {
          setLoaderValue((allFiles.length / dataRangeInMonths) * 100);
        }
        // else dataRangeInMonths is less than 1 or NaN.
        else {
          setLoaderValue((1 / 1) * 100);
        }
      }
      // if category is apart from bank and address and it is single download
      else {
        console.log("loader value check livefeedback");
        setLoaderValue((1 / 1) * 100);
      }
    }
  }, [monthsLiesInUploadBtn, monthsLiesInUploadedDocs]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        width: "100%",
      }}
    >
      {allLiveFeedbackResponses?.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {item.name ? (
                  <img src={check} height={14} width={14} />
                ) : (
                  <img src={wrong} height={14} width={14} />
                )}
                <span>Name</span>
              </div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {item.address ? (
                  <img src={check} height={14} width={14} />
                ) : (
                  <img src={wrong} height={14} width={14} />
                )}
                <span>Address</span>
              </div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {item.accountnumber ? (
                  <img src={check} height={14} width={14} />
                ) : (
                  <img src={wrong} height={14} width={14} />
                )}
                <span>Account number</span>
              </div>
              {category === "bank" && (
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {item.period ? (
                    <img src={check} height={14} width={14} />
                  ) : (
                    <img src={wrong} height={14} width={14} />
                  )}

                  <span>Period: {item.period}</span>
                </div>
              )}
            </div>
            {button?.multidownload && (
              <img
                src={deleteIcon}
                height={20}
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(index)}
                onKeyDown={() => handleDelete(index)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LiveFeedback;
