import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./statementUploader.css";
import { Modal, ModalBody, Button } from "reactstrap";
import "./statementUploader.css";
import {
  ALL_LIVEFEEDBACK_DATA,
  LIVEFEEDBACK_INVALID_DOC,
  REDIRECT_TO_UPLOAD,
} from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import diroLogo from "../assets/dirologo.svg";

const TechnicalErrorIconSvg = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 24 21"
  >
    <path
      fill="#FDB828"
      fillRule="nonzero"
      d="M23.344 17.47l-9.282-15.9c-1.135-1.944-2.989-1.944-4.124 0L.655 17.47C-.48 19.412.451 21 2.72 21H21.28c2.268 0 3.195-1.587 2.063-3.53zM10.724 6.08c.335-.357.757-.535 1.276-.535.52 0 .941.175 1.276.528.329.35.498.792.498 1.323 0 .453-.692 3.806-.923 6.242h-1.67c-.201-2.436-.955-5.789-.955-6.242 0-.524.17-.963.498-1.316zm2.527 11.591A1.75 1.75 0 0 1 12 18.18a1.75 1.75 0 0 1-1.251-.507 1.64 1.64 0 0 1-.523-1.227c0-.477.173-.891.523-1.237.35-.346.768-.517 1.251-.517.483 0 .901.17 1.251.517.35.346.523.76.523 1.237 0 .482-.173.889-.523 1.227z"
    />
  </svg>
);

var WrongDocument = ({
  allFiles,
  setAllFiles,
  setTryAgain,
  modalColor,
  PocError,
}) => {
  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const lastLinkClickedData = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess
  );

  const removeFileFromAllFiles = (index) => {
    const temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles([...temp]);
  };

  const removeLivefeedbackResponseFromAllResponses = (index) => {
    const temp = [...allLiveFeedbackResponses];
    temp.splice(index, 1);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [...temp] });
  };

  const handleDelete = () => {
    removeFileFromAllFiles(allFiles.length - 1);
    removeLivefeedbackResponseFromAllResponses(
      allLiveFeedbackResponses.length - 1
    );
  };

  const redirectToUpload = () => {
    console.log("allfiles length redirect to upload", allFiles);
    if (allFiles.length < 2) {
      dispatch({ type: REDIRECT_TO_UPLOAD });
    }
  };

  const getCategory = (category) => {
    if (category === "address") {
      return "utility bill";
    } else {
      return category;
    }
  };

  return (
    <Modal
      isOpen={true}
      className={"modal-centered"}
      backdropClassName="custom-backdrop"
      keyboard={false}
      backdrop={true}
      size="md"
      scrollable={true}
      style={{ width: "450px", margin: "auto" }}
      contentClassName="modal-content-style"
    >
      <ModalBody className=" model-body">
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "center",
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div>
              <TechnicalErrorIconSvg />
            </div>
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Invalid document
            </div>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {"The submitted document is not a valid " +
                  getCategory(lastLinkClickedData?.category) +
                  " statement."}
              </div>
            </div>

            <Button
              className="btn cbtn"
              id="browse"
              style={{
                // borderRadius: "35px",
                fontFamily: "Montserrat",
                // border: "solid 2px #00bcd4",
                backgroundColor: "black",
                color: "#fff",
                padding: "8px 30px",
                fontSize: "16px",
                fontWeight: "600",
                // width: "100%",
                // margin: "1rem 0rem 2rem 0rem",
              }}
              onClick={() => {
                dispatch({ type: LIVEFEEDBACK_INVALID_DOC, payload: false });
                handleDelete();
                redirectToUpload();
              }}
            >
              Try again
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              fontSize: "13px",
              fontWeight: 600,
            }}
          >
            Powered by
          </div>
          <img src={diroLogo} height={12} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WrongDocument;
