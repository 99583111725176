import axios from "axios";
import {
  VERIFICATION_LINKPOC_START,
  VERIFICATION_LINKPOC_SUCCESS,
  VERIFICATION_LINKPOC_FAILURE,
  LASTLINK_CLICK_START,
  LASTLINK_CLICK_SUCCESS,
  LASTLINK_CLICK_FAILURE,
  GET_LAST_CLICKED_LINK,
} from "./actionTypes";
import { env as environment } from "../environments";
import { sendLogs } from "../helpers/sendLogs";
import { updateSession } from "../helpers/updateSessionHandler";
import { sendSentryLogs } from "../helpers/sentry_logs";

export const VerificationLinkPoc = (params) => {
  return async (dispatch) => {
    let sessionId = null;
    const verjson = {
      buttonid: params.buttonid ? params.buttonid : params.ibuttonid,
      user_info: {
        firstname: "demo",
        lastname: "",
        email: "demo@diro.io",
        mobile: "",
        mcc: "+91",
        dob: "",
        zipcode: "",
      },
      warn_case: [
        {
          hinttext: "Jane",
          proceed: false,
          hovertext: "profile",
          keyword: "",
          message: "First name",
          present: false,
          type: "text",
        },
      ],
      paramList: {
        buttonid: params.buttonid ? params.buttonid : params.ibuttonid,
        trackid: params.trackid ? params.trackid : "",
      },
    };

    const newWarn_objects = Object.keys(params)
      .filter((key) => key !== "buttonid" && key !== "trackid")
      .map((key) => ({
        hinttext: params[key],
        proceed: false,
        hovertext: "profile",
        keyword: key,
        message: params[key],
        present: false,
        type: "text",
      }));
    verjson.warn_case.push(...newWarn_objects);

    const hasOtherParams = Object.keys(params).some(
      (key) => key !== "buttonid" && key !== "trackid"
    );

    if (hasOtherParams) {
      verjson.warn_case.shift();
    }

    if (params.trackid) {
      verjson.paramList["trackid"] = params.trackid;
      var warnObj = {
        hinttext: "trackid",
        proceed: false,
        hovertext: "trackid",
        keyword: params.trackid,
        message: "trackid",
        present: false,
        type: "trackid",
      };
      verjson.warn_case.push(warnObj);
    }

    const headers = { "Content-Type": "application/json" };
    dispatch({ type: VERIFICATION_LINKPOC_START });
    try {
      const pocResponse = await axios.post(
        environment.getverificationlink,
        verjson,
        { headers: headers }
      );

      if (pocResponse.status === 200) {
        await dispatch({
          type: VERIFICATION_LINKPOC_SUCCESS,
          payload: pocResponse?.data,
        });
        const nses = pocResponse?.data?.verificationlink?.lastIndexOf("/");
        sessionId = pocResponse?.data?.verificationlink?.substring(nses + 1);
        localStorage.setItem("sessionId", sessionId);

        // Log the success of VerificationLinkPoc API call
        await sendLogs(
          "VerificationLinkPoc Called.",
          "VerificationLinkPoc Success",
          "Uploadaction.js",
          sessionId,
          "2"
        );
        try {
          dispatch({ type: LASTLINK_CLICK_START });
          const lastClickedJson = { sessionid: sessionId };
          const response = await axios.post(
            environment.get_lastclicked_link,
            lastClickedJson
          );
           
          dispatch({ type: GET_LAST_CLICKED_LINK, payload: response?.data });

          if (response.status === 200) {
            await dispatch({
              type: LASTLINK_CLICK_SUCCESS,
              payload: response?.data,
            });

            const geoIpData = JSON.parse(localStorage.getItem("geoipResponse"));

            console.log("geoIpData response from local storage in verificationLinkPoc file",geoIpData )

            const ipDataForSession = {};

            var geoIpSentryLogMessage = {
              version: "1.0",
              host: "pdf verifier upload Process",
              short_message: "geoIpData & user_ip failed",
              full_message: "geoIpData & user_ip are not coming using mxid",
              timestamp: Date.now() / 1000,
              level: 3,
              facility: "uplaods3_Action.js",
              _sessionid: sessionId,
              app_name: "pdf upload Verifier",
              _ENV: "stage2",
            };

            if(geoIpData){
              ipDataForSession.geoIpData = geoIpData;
              ipDataForSession.user_ip = geoIpData?.traits?.ip_address;
            }else{
              ipDataForSession.user_ip = "00.00.00.00";
              await sendSentryLogs(geoIpSentryLogMessage)
              await sendLogs(
              "geoIpData & user_ip failed",  "geoIpData & user_ip are not coming using mxid", "/updatesession", sessionId,
              );
            }

            var uploadalpha2code={
              sessionid: sessionId,
              data: {
                alpha2code:localStorage.getItem('iso_code'),
                ...ipDataForSession
              },
            };

            await dispatch(updateSession(uploadalpha2code,sessionId))

            // Log the success of GetLastLinkClicked API call
            await sendLogs(
              "lastLinkClicked Called Success",
              "GetLastLinkClicked called successfully",
              "uploadActions.js",
              sessionId,
              "3"
            );
          } else {
            await sendLogs(
              "lastLinkClicked failed ",
              "LastLinkClicked Called Failed.",
              "uploadActions.js",
              sessionId,
              "3"
            );
          }
        } catch (err) {
          dispatch({
            type: LASTLINK_CLICK_FAILURE,
            payload: err.response,
          });
          var SentryLogMessage = {
            version: "1.0",
            host: "pdf verifier upload Process",
            short_message: "GetlastLinkClicked Called Failed",
            full_message: `GetlastLinkClicked Failed with error: ${err.message}`,
            timestamp: Date.now() / 1000,
            level: 3,
            facility: "uplaods3_Action.js",
            _sessionid: sessionId,
            app_name: "pdf upload Verifier",
            _ENV: "stage2",
          };
          await sendSentryLogs(SentryLogMessage)
          await sendLogs(
            "GetlastLinkClicked Called Failed.",
            err.response.data.detail,
            "uploadActions.js",
            sessionId,
            "4"
          );
        }
      }
    } catch (err) {
      dispatch({
        type: VERIFICATION_LINKPOC_FAILURE,
        payload: err.response?.data?.msg,
      });
      var SentryLogMessage2 = {
        version: "1.0",
        host: "pdf verifier upload Process",
        short_message: "VerificationLinkPOC Failure",
        full_message: `VerificationLinkPOC Failed with error: ${err.message}`,
        timestamp: Date.now() / 1000,
        level: 3,
        facility: "uplaods3_Action.js",
        _sessionid: sessionId,
        app_name: "pdf upload Verifier",
        _ENV: "stage2",
      };
      await sendSentryLogs(SentryLogMessage2)

      await sendLogs(
        "VerificationLinkPoc Failure.",
        err.response?.data?.msg,
        "Uploadaction.js",
        sessionId,
        "2"
      );
    }
  };
};
