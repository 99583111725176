import React, { useState, useRef, useEffect, } from "react";
import { Button, Col } from "reactstrap";
import sealImage from "../../assets/Group 1324.png";
import { redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileAction } from "../../../actions/Uploadaction";
// import {handleVerification} from '../../../actions/capture_process'
import PleaseWaitModel from "../../templates/waiting.template";
import TechnicalErrorModel from "../../templates/technicalError.template";
import ThankYouModel from "../../templates/thankyou.template";
import UploadingDone from "../../templates/uploading_done.template";
import UpLoadingModal from "../../templates/uploading.template";
import Verifying from "../../templates/verifying.template";
import UnsupportedMedia from "../../templates/unsupported_file.template";
import { updateSession } from "../../../helpers/updateSessionHandler";
import {
  PRIVACY_SCREEN_START,
  PRIVACY_SCREEN_CLOSE,
} from "../../../actions/actionTypes";
import Reportissue from "../../templates/reportIssue.template";
import "./index.css";
import ButtonError from "../../templates/button_error_template.";
import PrivacyTemplate from "../../templates/privacy.template";
import { s3fileHandler } from "../../../helpers/uploadS3Handler";
// import PreviewScreen from "../../newTemplates/previewPage";

const UploadFile = ({ verifiedData, isVerifying }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showInvalidFileModal, setShowInvalidFileModal] = useState(false);
  const [showPrivacyModalPOP, setShowPrivacyModalPOP] = useState(false);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.upload);
  const uplaodFileSuccess = useSelector(
    (state) => state.upload?.uploadedFile?.message
  );
  console.log("upload success", uplaodFileSuccess);
  const params = useSelector((state) => state.upload?.params);
  const uploadingS3 = useSelector((state) => state.uploadS3);
  const verificationlinkPOC = useSelector(
    (state) => state?.verificationLinkPOC
  );
  const lastLinkClicked = useSelector((state) => state.lastLinkClicked);
  const pdfDataBase64 = useSelector((state) => state.pdfData);
  const liveFeedBack = useSelector((state) => state.livefeedback);
  const oneShotUpload = useSelector((state) => state.oneShotUpload);
  const showPrivacyModal = useSelector((state) => state.privacyScreen);
  const sessionId = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.sessionid
  );
  const category = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.category
  );

  const handleFileSelect = async (event) => {
    console.log("browse fun");
    event.preventDefault();
    const file = event.target.files[0] || event.dataTransfer.files[0];

    var notSupportedDocument = {
      sessionid: sessionId,
      data: {
        indicator: {
          progress_no: "6",
          progress_name: "uploaded document not supported",
          Detection: "upload",
        },
      },
    };
    if (file && file.type === "application/pdf") {
      console.log("browse fun if");
      dispatch(uploadFileAction(file, params, category, sessionId));
    } else {
      console.log("browse fun else");
      setSelectedFile(null);
      console.log(showInvalidFileModal, "here is show invalid file status");
      setShowInvalidFileModal(true);
      if (params.buttonid) {
        dispatch(updateSession(notSupportedDocument, sessionId));
      }
    }
    event.target.value = null;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    var notSupportedDocument = {
      sessionid: sessionId,
      data: {
        indicator: {
          progress_no: "6",
          progress_name: "uploaded document not supported",
          Detection: "upload",
        },
      },
    };
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
      dispatch(uploadFileAction(file, params, category, sessionId));
    } else {
      setSelectedFile(null);
      setShowInvalidFileModal(true);
      if (params.buttoid) {
        dispatch(updateSession(notSupportedDocument, sessionId));
      }
    }
  };

  const handleBrowseClick = () => {
  // Trigger click event on file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCloseModal = () => {
    setShowInvalidFileModal(false);
  };
  return (
    <>
      <div>
        {verificationlinkPOC?.error  && (
          <ButtonError
            modalColor={lastLinkClicked?.dataSuccess?.data?.setcolor}
            PocError={verificationlinkPOC?.error}
          />
        )}

        {(lastLinkClicked.error ||
          pdfDataBase64.error ||
          liveFeedBack.error ||
          upload.error) && (
          <TechnicalErrorModel
            modalColor={lastLinkClicked.dataSuccess?.data?.setcolor}
          />
        )}

        {showInvalidFileModal && (
          <UnsupportedMedia
            modalColor={lastLinkClicked.dataSuccess?.data?.setcolor}
            onClose={handleCloseModal}
          />
        )}


        {params.buttonid &&
        (!verificationlinkPOC.error &&(
          ((verificationlinkPOC.verificationPocStart ||
            !lastLinkClicked.dataSuccess )) && (
            <PleaseWaitModel
              modalColor={lastLinkClicked.dataSuccess?.data?.setcolor}
            />
          )
        ))
        }

        {lastLinkClicked.dataSuccess && (
          <PrivacyTemplate
            modalColor={lastLinkClicked.dataSuccess?.data?.setcolor}
          />
        )}

        {(uploadingS3.uploading || liveFeedBack.feedbackStart) && (
          <UpLoadingModal
            modalColor={lastLinkClicked.dataSuccess?.data?.setcolor}
          />
        )}

        {!oneShotUpload.oneShotStart &&
          !oneShotUpload.oneShotSuccess &&
          liveFeedBack.feedbackSuccess && (
            <UploadingDone
              modalColor={lastLinkClicked.dataSuccess?.data?.setcolor}
            />
          )}

        {oneShotUpload.oneShotStart && !oneShotUpload.oneShotSuccess && (
          <Verifying modalColor={lastLinkClicked.dataSuccess?.data?.setcolor} />
        )}
        {oneShotUpload.oneShotSuccess && (
          <ThankYouModel
            modalColor={lastLinkClicked.dataSuccess?.data?.setcolor}
          />
        )}
      </div>
      <div
        onClick={verifiedData ? handleBrowseClick : null}
        className="card-body sealmanage wrapper"
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          opacity: 1,
          border: "dashed 2px rgba(253, 184, 40, 0.5)",
          borderRadius: "4px",
          padding: "0px",
          position: "relative",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {isVerifying ? (
          <div className="verifying"> Verifying...</div>
        ) : (
          <div
            className={
              verifiedData
                ? "rectangle-210-with-verified-data"
                : "rectangle-210"
            }
          >
            <div
              className="Drag-drop-your-original-PDF-here"
              style={{ color: "#858585" }}
            >
              <div
                className="text-style-1"
                style={
                  verifiedData
                    ? {
                        fontSize: "26px",
                        marginBottom: "0px",
                        fontFamily: "Montserrat",
                      }
                    : {
                        fontSize: "40px",
                        marginBottom: "0px",
                        fontFamily: "Montserrat",
                      }
                }
              >
                {params.buttonid
                  ? lastLinkClicked.dataSuccess?.data?.welcomePage?.gototext
                    ? lastLinkClicked.dataSuccess.data.welcomePage.gototext
                    : `Upload your latest ${category} statement`
                  : "Drag & drop to verify"}
              </div>
              <div
                className="text-style-2"
                style={{ fontSize: "22px", fontFamily: "Montserrat" }}
              >
                {!params || !params.buttonid
                  ?
                    verifiedData
                    ? "another original PDF here"
                    : "your original PDF here"
                  : null}
              </div>
            </div>

            <Col className="text-center mt-5 mb-5">
              {!verifiedData && (
                <Button
                  className="btn cbtn"
                  id="browse"
                  style={{
                    borderRadius: "26px",
                    fontFamily: "Montserrat",
                    border: "solid 2px #00bcd4",
                    backgroundColor: "#00bcd4",
                    color: "#fff",
                    padding: "10px 20px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                  onClick={handleBrowseClick}
                >
                  Or browse file
                </Button>
              )}
              <input
                ref={fileInputRef}
                className="d-none"
                type="file"
                onChange={handleFileSelect}
              />
            </Col>

            {!verifiedData && (
              <Col className="text-center mb-2">
                <p
                  className="Maximum-combined-file-size-100MB-up-to-50-files"
                  style={{ color: "#292929", fontFamily: "Montserrat" }}
                >
                  Maximum combined file size: 100MB, up to 50 files
                </p>
              </Col>
            )}
          </div>
        )}
      <p style={{fontSize:"13px",fontFamily:"monospace",fontWeight:"bold", color:"#887a7a",textAlign:"end", marginRight:"1rem"}}> {sessionId && sessionId.toUpperCase()}</p>
      {!sessionId && (
        <div
          className="seal"
          style={{
            position: "absolute",
            bottom: "-60px",
            right: "-40px",
            zIndex: 0,
          }}
        >
          <img
            src={sealImage}
            alt="Seal"
            style={{ width: "7rem", height: "auto" }}
          />
        </div>
         )}
      </div>
       
    </>
  );
};

export default UploadFile;
