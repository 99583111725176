import axios from "axios";
import { env as environment } from "../environments";
import { LIVEFEEDBACK_INVALID_DOC, LIVEFEEDBACK_START, LIVEFEEDBACK_SUCCESS, PASSWORD_PROTECTED, SOMETHING_WENT_WRONG } from "./actionTypes";
import { updateSession } from "../helpers/updateSessionHandler";
import {unsupportedMedia} from "./Uploadaction";

export const liveFeedback =  (file,category,sessionId) => {
    
  return async (dispatch) => {
    try {
    if (category === "bank" || category === "address") {
      dispatch({ type: LIVEFEEDBACK_START });

      const Header = {
        "Content-Type": "application/json",
      };

      const liveFeedbackForm = new FormData();
      let datetime = new Date().getTime().toString();

      const key = sessionId + "-" + datetime + ".pdf";
      liveFeedbackForm.append("file", file, key);
      liveFeedbackForm.append("category", category);
      liveFeedbackForm.append("sessionid", sessionId);


      const response = await axios.post(
        environment.getProcessedDataFromChatGpt,
        liveFeedbackForm,
        Header
      );
      var send_livefeedback = {
        sessionid: sessionId,
        data: { livefeedback: response.data },
      };
      dispatch(updateSession(send_livefeedback,sessionId));
    //   if (
    //     response.status === 200 &&
    //     response?.data.document_type === "Invalid"
    //   ) {
    //     await sendLogs(
    //       "GPT liveFeedbackForm Process",
    //       "Uploaded document is inavlid! ",
    //       "uplaods3_Action.js",
    //       sessionId,
    //       "4"
    //     );
    //   } else {
    //     await sendLogs(
    //       "GPT liveFeedbackForm Process",
    //       "Uploaded document is valid! ",
    //       "uplaods3_Action.js",
    //       sessionId,
    //       "4"
    //     );
    //   }

    console.log("response livefeedback check", response?.data)
    console.log("check check",response?.data?.response?.document_type,response.status,response)
    if (
      response.status === 200 &&
      response?.data?.response?.document_type === "Invalid"
    ) {
      // To call the wrong document popup, call the below action with the given payload
      console.log("invalid action if block")
      dispatch({ type: LIVEFEEDBACK_INVALID_DOC, payload:true });
    }
    else if(
      response?.data?.error == true &&
      response?.data?.message?.toLowerCase().includes("password")
    ) {
      dispatch({type:PASSWORD_PROTECTED, payload:true})
    }
    else if(response.status !== 200){
      dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
    }

      const chatGptResponse = response?.data;
      dispatch({ type: LIVEFEEDBACK_SUCCESS, payload: chatGptResponse });
    
    //   var data = {
    //     sessionid: sessionId,
    //     data: {
    //       s3filename: [key],
    //     },
    //   };
    //   await dispatch(updateSession(data, sessionId));
    }
}catch(error){
console.log("err",error);
// if(response.status === 413){
  dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
// }
}
  }
}